import * as React from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'store';

import './Footer.less';
import { secondaryThemeCSS } from 'data/theme/themeSelectors';


const Footer: React.FC = () => {
  const theme = useSelector<IRootState, React.CSSProperties>(secondaryThemeCSS);

  return (
    <footer className='footer' style={theme}>
      <div className='footer__title'>
        ontzorg.net
        <span className='u-text-lighter'>
          ontzorgt
        </span>
      </div>
      <span className='footer__copy'>
        &copy;{(new Date().getFullYear())} ontzorg.net b.v.
      </span>
    </footer>
  );
};

export default Footer;

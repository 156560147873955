export interface IjwtPayload {
  name: string;
  exp: number;
  readonly: boolean;
  last_login: string;
}

export function decodeJwt(jwt: string): IjwtPayload {
  return JSON.parse(atob(jwt.split('.')[1]));
}

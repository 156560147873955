import * as React from 'react';
import { Dispatch } from 'redux';
import * as classnames from 'classnames';
import { connect } from 'react-redux';

/* Redux */
import { IRootState } from 'store';
import { logout } from 'data/session/sessionActions';
import { hexToRgba } from 'services/util';
import { IThemeState } from 'data/theme/themeReducer';

/* Generic components */
import SvgIcon from 'components/SvgIcon';


interface IStateToProps {
  name: string;
  last_login: string;
  theme: IThemeState;
}

interface IDispatchToProps {
  logout(): void;
}

interface IState {
  active: boolean;
}

interface IProps extends IStateToProps, IDispatchToProps { }

class HeaderUserDropdown extends React.Component<IProps, IState> {
  private wrapper: HTMLDivElement;

  public state: IState = {
    active: false,
  };

  private toggleActive = (): void => (
    this.setState({ active: !this.state.active })
  )

  public componentDidMount(): void {
    document.addEventListener('mousedown', this.handleClickOutside.bind(this));
  }

  public componentWillUnmount(): void {
    document.removeEventListener('mousedown', this.handleClickOutside.bind(this));
  }

  public handleClickOutside(event: React.MouseEvent<HTMLElement>): void {
    if (this.wrapper !== null && !this.wrapper.contains(event.target as Node) && this.state.active) {
      this.toggleActive();
    }
  }


  public render(): JSX.Element {
    const { theme } = this.props;

    const navWrapperStyling = classnames({
      'iconNav__wrapper': true,
      'is-open': this.state.active,
    });

    return (
      <div className={navWrapperStyling} ref={(c) => { this.wrapper = c; }}>
        <button
          className='iconNav__item'
          onClick={this.toggleActive.bind(this)}
          style={this.state.active ? { backgroundColor: theme.header.background, color: theme.header.text } : { color: theme.primary.background }}
        >
          <SvgIcon icon={this.state.active ? 'circle-close' : 'user'} />
        </button>
        <div className='iconNav__dropdown' style={{ backgroundColor: theme.header.background, color: theme.header.text }}>
          <div className='userDropdown'>
            <div className='userDropdown__info'>
              <div className='userDropdown__info-icon' style={{ color: theme.secondary.background }}>
                <SvgIcon icon='user' />
              </div>
              <div className='userDetail__info-details'>
                <div className='userDropdown__info-name'>{this.props.name}</div>
                <div className='userDropdown__info-login'>Laatste login: {this.props.last_login}</div>
              </div>
            </div>
            <button className='userDropdown__btn' onClick={this.props.logout} style={{ color: theme.header.text }}>
              <span>Uitloggen</span>
              <span className='userDropdown__btn-icon'>
                <SvgIcon icon='angle-right' />
              </span>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IRootState): IStateToProps => ({
  name: state.session.userData.name,
  last_login: state.session.userData.last_login,
  theme: state.theme,
});

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => ({
  logout: () => dispatch(logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderUserDropdown);

import * as React from 'react';
import * as classnames from 'classnames';

import Config from 'constants/Config';
import { IQueryStringOptions } from 'services/pagination';


interface IProps {
  total: number;
  currentPage: number;
  onPaginate(paginationOptions: IQueryStringOptions): any;
}

class ListPagination extends React.Component<IProps> {
  private handlePreviousBtn(): void {
    this.handlePagenumberClick(this.props.currentPage - 1);
  }

  private handleNextBtn(): void {
    this.handlePagenumberClick(this.props.currentPage + 1);
  }

  private handlePagenumberClick(pageNumber: number): void {
    this.props.onPaginate({
      limit: Config.PAGINATION_CAP,
      offset: (pageNumber * Config.PAGINATION_CAP) - Config.PAGINATION_CAP,
    });
  }

  private determinePageCount(): number {
    return Math.ceil(this.props.total / Config.PAGINATION_CAP);
  }

  private createPaginationItems(): JSX.Element[] {
    /**
     * Split pagination if the amount of pages exceeds 8 pages
     */
    if (this.determinePageCount() > 10) {
      const { currentPage } = this.props;

      const lastPage = this.determinePageCount();
      const pageOffset = 2;
      const offsetPages = [];

      
      for (let i = currentPage - pageOffset; i <= currentPage + pageOffset; i++) {
        i > 0 && i <= lastPage && offsetPages.push(i);
      }

      offsetPages.indexOf(1) < 0 && offsetPages.unshift(1);
      offsetPages.indexOf(lastPage) < 0 && offsetPages.push(lastPage);

      return offsetPages.map((pageNumber: number, index) => {
        const buttonStyling = classnames({
          'listnav__link': true,
          'is-current': this.props.currentPage === pageNumber
        });

        return (
          <li key={index} className='listnav__item'>
            <button className={buttonStyling} onClick={() => this.handlePagenumberClick(pageNumber)}>
              {pageNumber}
            </button>
          </li>
        );
      });
    }

    /**
     * No split pagination
     */
    return Array.from([...Array(this.determinePageCount())]).map((v, index) => {
      const humanPageNumber = index + 1; /* Starting from 1 */
      const buttonStyling = classnames({
        'listnav__link': true,
        'is-current': this.props.currentPage === humanPageNumber,
      });
      // console.ll;

      return (
        <li key={index} className='listnav__item'>
          <button className={buttonStyling} onClick={() => this.handlePagenumberClick(humanPageNumber)}>
            {humanPageNumber}
          </button>
        </li>
      );
    });
  }
  
  private getPrevNextBtnStyling = (disableAt: number) => classnames({
    'listnav__button': true,
    'listnav__button--disabled': this.props.currentPage === disableAt,
  })

  public render(): JSX.Element {
    const { currentPage } = this.props;
    const pageNumbers = this.createPaginationItems();

    if (currentPage > 4) {
      pageNumbers.splice(1, 0, (
        <li key={1001} className='listnav__divider'>...</li>
      ));
    }

    if (currentPage + 4 < this.determinePageCount()) {
      pageNumbers.splice(pageNumbers.length - 1, 0, (
        <li key={1099} className='listnav__divider'>...</li>
      ));
    }

    return (
      <nav className='listnav'>
        <ol className='listnav__list'>
          <li className='listnav__item'>
            <button
              disabled={currentPage === 1}
              className={this.getPrevNextBtnStyling(1)}
              onClick={this.handlePreviousBtn.bind(this)}>
              Vorige
            </button>
          </li>

          {pageNumbers}

          <li className='listnav__item'>
            <button
              disabled={currentPage === this.determinePageCount()}
              className={this.getPrevNextBtnStyling(this.determinePageCount())}
              onClick={this.handleNextBtn.bind(this)}>
              Volgende
            </button>
          </li>
        </ol>
      </nav>
    );
  }
}

export default ListPagination;
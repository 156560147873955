import { AxiosResponse, AxiosError } from 'axios';
import { call, put } from 'redux-saga/effects';

/* Services */
import API from 'services/API';

/* Data */
import { IPureAction, ActionType } from 'data/actions';
import { setUserFilterOptions, setTimefilterOptions } from 'data/filters/filterActions';
import { logout } from 'data/session/sessionActions';
import { IAuthenticated } from 'data/session/sessionModels';


export function* fetchUserFilterOptions(action: IPureAction<ActionType.LOGIN_SUCCESS, IAuthenticated>): any {
  try {
    const response: AxiosResponse = yield call(API.fetch, 'filters');
    yield put(setUserFilterOptions(response.data));
  } catch (err) {
    yield put(logout());
  }
}

export function* fetchTimefilterOptions(action: IPureAction<ActionType.LOGIN_SUCCESS, IAuthenticated>): any {
  try {
    const response: AxiosResponse = yield call(API.fetch, 'timefilters');
    yield put(setTimefilterOptions(response.data));
  } catch (err) {
    yield put(logout());
  }
}
export default class LocalStorageManager {
  public static addKey(key: string, value: string): void {
    try {
      window.localStorage.setItem(key, value);
      // console.log(`Added "${key}" to the local storage (${value})`);
    } catch (e) {
      console.log(`Failed to add ${key} to the localStorage because of "${JSON.stringify(e)}"`);
    }
  }

  public static getKey(key: string): string {
    try {
      const value = window.localStorage.getItem(key);

      if (!value) {
        throw new Error('Key does not exist');
      }

      return value;
    } catch (e) {
      console.log(`Failed to get the key "${key}" from the localStorage because of: "${e}"`);
    }
  }

  public static removeKey(key: string): void {
    try {
      window.localStorage.removeItem(key);
      // console.log(`Removed "${key}" from localStorage`);
    } catch (e) {
      console.log(`Failed to remove ${key} from the localStorage because of "${JSON.stringify(e)}"`);
    }
  }

  public static setJwt(jwt: string): void {
    LocalStorageManager.addKey('jwt', jwt);
  }

  public static getJwt(): string {
    return LocalStorageManager.getKey('jwt');
  }

  public static removeJwt(): void {
    LocalStorageManager.removeKey('jwt');
  }
}
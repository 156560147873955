import * as React from 'react';
import * as classnames from 'classnames';

interface IProps extends React.SVGProps<SVGElement> {
  icon: string;
}

const SvgIcon: React.FC<IProps> = ({ icon, className }) => {
  const styling = classnames({
    icon: true,
    [className]: Boolean(className),
  });

  return (
    <svg className={styling}>
      <use xlinkHref={`#icon-${icon}`} />
    </svg>
  );
};

export default SvgIcon;

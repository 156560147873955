import * as React from 'react';

import RequestOptionsEnhancer from './RequestOptionsEnhancer';
import withRequest, { IRequestProps } from './request';

import { IPropRequestOptions, RequestHoC } from 'data/request/requestModels';
import { objectContainsKeys } from '../../services/util';

import * as RouteParser from 'route-parser';

/**
 * Map props to API url
 * 
 * @param options 
 * @param Component 
 */
function withRequestByProp<P, S>(
  options: IPropRequestOptions,
  Component: RequestHoC<P>
): React.ComponentClass<P> {

  class PropRequestEnhancer extends RequestOptionsEnhancer<P & IRequestProps, S> {
    protected options: IPropRequestOptions = options;
    protected ComponentToEnhance: RequestHoC<P> = Component;

    private performRequestByProp(props: IRequestProps): void {
      // Create parser for URL in options, i.e. /users/:userId
      const parser = new RouteParser(options.url);

      // Match props to parameters in URL from options
      const url = parser.reverse(props);

      // Check if reversed URL contains all requested parameters
      if (!(objectContainsKeys(parser.match(url as string), props))) {
        throw new Error(`Parameters in options URL ${options.url} do not match with received props`);
      }

      this.setOptionsKey('url', url);
    }

    public componentWillMount(): void {
      this.performRequestByProp(this.props);
    }

    public componentWillReceiveProps(nextProps: IRequestProps): void {
      this.performRequestByProp(nextProps);
    }
  }

  return PropRequestEnhancer;
}

export default withRequestByProp;

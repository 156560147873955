export interface IPaginationHeaders {
  'x-current-page': string;
  'x-total-count': string;
}

export interface IPaginationOptions {
  offset?: number;
  limit?: number;
}

export interface IQueryStringOptions extends IPaginationOptions {
  filter?: string;
  organisation?: string;
  order_col?: string;
  order?: 'asc' | 'desc';
}

export function constructQuerystring(options: IQueryStringOptions = {}): string {
  const querystring = Object.keys(options).reduce((qs, key) => {
    if (typeof options[key] === 'undefined') {
      return qs;
    }

    if (typeof options[key] === 'string' && options[key].length === 0) {
      return qs;
    }

    return `${qs}${key}=${options[key]}&`;
  }, '?');

  /* Remove the last &-sign */
  return querystring.substring(0, querystring.length - 1);
}
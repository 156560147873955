import * as React from 'react';

import { IRequestOptions, RequestHoC } from 'data/request/requestModels';
import withRequest, { IRequestProps } from './request';


export default abstract class RequestOptionsEnhancer<P, S, E = {}> extends React.Component<P & E, S> {
  protected abstract options: IRequestOptions;
  protected abstract ComponentToEnhance: RequestHoC<P>;

  protected setOptionsKey(key: string, value: any): void {
    this.options = {
      ...this.options,
      [key]: value,
    };
  }

  public render(): JSX.Element {
    const EnhancedComponent = withRequest(this.options, this.ComponentToEnhance);
    return <EnhancedComponent {...this.props} />;
  }
}
import { IThemeState } from './themeReducer';
import { IRootState } from 'store';

export const headerThemeCSS = (state: IRootState): React.CSSProperties => ({
  backgroundColor: state.theme.header.background,
  color: state.theme.header.text,
});

export const primaryThemeCSS = (state: IRootState): React.CSSProperties => ({
  backgroundColor: state.theme.primary.background,
  color: state.theme.primary.text,
});

export const secondaryThemeCSS = (state: IRootState): React.CSSProperties => ({
  backgroundColor: state.theme.secondary.background,
  color: state.theme.secondary.text,
});
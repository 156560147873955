import { IPureAction, ActionType, IAction } from 'data/actions';
import { takeEvery } from 'redux-saga/effects';

import * as sessionSagas from './session/sessionSagas';
import * as filterSagas from './filters/filterSagas';
import * as organisationSagas from './organisations/organisationsSagas';


const patternMap = (type: ActionType) => (action: IAction): boolean => (
  action.type === type
);

function* allSagas(): any {
  yield takeEvery(patternMap(ActionType.VALIDATE_TOKEN), sessionSagas.performTokenValidation);
  yield takeEvery(patternMap(ActionType.LOGIN_SUCCESS), sessionSagas.uponAuthenticated);
  yield takeEvery(patternMap(ActionType.LOGIN_SUCCESS), filterSagas.fetchUserFilterOptions);
  yield takeEvery(patternMap(ActionType.LOGIN_SUCCESS), filterSagas.fetchTimefilterOptions);
  yield takeEvery(patternMap(ActionType.LOGIN_SUCCESS), organisationSagas.fetchOrganisations);
  yield takeEvery(patternMap(ActionType.LOGOUT), sessionSagas.uponLogout);
}

export default allSagas;

import * as React from 'react';
import { useSelector } from 'react-redux';
import * as classnames from 'classnames';

import { IRootState } from 'store';
import { primaryThemeCSS } from 'data/theme/themeSelectors';

/* Stylesheet */
import './SpinnerButton.less';


interface IProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  activate?: boolean;
}

const SpinnerButton: React.FC<IProps> = ({ className, activate, ...rest }) => {
  const theme = useSelector<IRootState, React.CSSProperties>(primaryThemeCSS);

  const styling = classnames({
    'spinner-btn': true,
    'spinner-btn--running': activate,
    [className]: Boolean(className),
  });

  return <button className={styling} {...rest} style={theme} />;
};

export default SpinnerButton;

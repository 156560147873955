import { IFilterState } from './filtersReducer';
import { IUserFilterOption, ITimefilterOption } from './filterModels';

export const setUserFilterOptions = (state: IFilterState, options: IUserFilterOption[]): IFilterState => ({
  ...state,
  users: options,
});

export const setTimefilterOptions = (state: IFilterState, options: ITimefilterOption[]): IFilterState => ({
  ...state,
  time: options,
});

import * as React from 'react';
import * as classnames from 'classnames';

interface IProps {
  children: JSX.Element;
  title: string;
  open?: boolean;
}

interface IState {
  open: boolean;
}

class UserListItemDropfilter extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    this.state = {
      open: props.open || false,
    };
  }

  public handleToggle(): void {
    this.setState((prevState) => ({ open: !prevState.open }));
  }

  public componentWillUnmount(): void {
    this.setState({
      open: false
    });
  }

  public render(): JSX.Element {
    const { children, title } = this.props;

    const styling = classnames({
      'is-open': this.state.open,
      'dropFilter__component': true,
      'dropFilter__component--collapsed': true,
    });

    return (
      <div className={styling}>
        <h5 className='dropFilter__title' onClick={this.handleToggle.bind(this)}>{title}</h5>
        <ul className='dropFilter__list'>
          {children}
        </ul>
      </div>
    );
  }
}

export default UserListItemDropfilter;
import { IFilterData } from './listsModels';

const reportStartYear = 2017;
const reportCurrentYear = (new Date()).getFullYear();

// Current active report is report for this year
export const reportCurrentActive = reportCurrentYear - reportStartYear + 1;

// Create report years based on start year and current year
export const reportYears: IFilterData[] = Array.from({length: reportCurrentActive}, (_, k) => {
  const year = reportStartYear + k;

  return {
    id: k + 1,
    label: '' + year,
    data: year
  };
});

export const reportActivity: IFilterData[] = [
  {
    id: 1,
    label: 'Actief',
    data: 1000,
  },
  {
    id: 2,
    label: 'Verwijderd',
    data: 0,
  }
];

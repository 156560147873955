import * as React from 'react';
import * as classnames from 'classnames';
import withRequest, { IRequestProps } from 'components/requests/request';
import PaginatedRequestComponent from 'components/requests/PaginatedRequestComponent';
import RequestComponent from 'components/requests/RequestComponent';

/* Generic components */
// import ListActiveFilters from 'components/list/ListActiveFilters';
import ListPage from 'components/list/ListPage/ListPage';
import ListHeader from 'components/list/ListHeader';
import ListTitle from 'components/list/ListTitle';
import ListBody from 'components/list/ListBody';
import ListNavItem from 'components/list/ListNavItem';
import Button from 'components/Button/Button';
import ListSkeleton from 'components/list/ListSkeleton';

/* Nested components */
import InactiveUsersListHead from './components/InactiveUsersListHead';
import InactiveUsersListItem from './components/InactiveUsersListItem';
import { IUser } from 'data/users/usersModels';
import { IPaginationHeaders, IQueryStringOptions } from 'services/pagination';
import Config from 'constants/Config';

/* Services */
import API from 'services/API';
import { RequestStatus } from 'data/request/requestModels';
import withAuthorization, { InjectedAuthProps } from 'components/hoc/withAuthorization';

interface ICheckedItem {
  index: number;
  username: string;
}
interface IState {
  checkedIds: {
    [index: number]: string;
  };
  requestStatus: RequestStatus;
}

const fetchOptions: IQueryStringOptions = {
  order_col: 'last_seen',
  order: 'asc',
};

class InactiveUsers extends RequestComponent<InjectedAuthProps, IState> {
  public state: IState = {
    checkedIds: {},
    requestStatus: undefined,
  };

  /**
   * Http Methods
   */
  private async doDeleteUsers(): Promise<void> {
    const { checkedIds } = this.state;
    const payload = Object.keys(checkedIds).map((index) => checkedIds[index]);

    try {
      await this.setState({ requestStatus: RequestStatus.IN_PROGRESS });
      await API.post('/users/delete', payload);
      await this.setState({ checkedIds: {}, requestStatus: RequestStatus.SUCCESS });
      this.doRequest(fetchOptions);
    } catch (err) {
      await this.setState({ requestStatus: RequestStatus.FAILED });
    }

    await new Promise((resolve) => setTimeout(() => resolve(), 2500));
    this.setState({ requestStatus: undefined });
  }



  /**
   * Functions responsible for toggling checkboxes.
   */
  private onToggleAll(): void {
    if (this.isEverythingChecked()) {
      this.setState({ checkedIds: [] });
    } else {
      const newState = {};
      this.props.request.data.forEach((user: IUser, index: number) => {
        newState[index] = user.username;
      });
      this.setState({
        checkedIds: newState,
      });
    }
  }

  private isEverythingChecked = (): boolean => (
    this.props.request.data && (
      Object.keys(this.state.checkedIds).length === this.props.request.data.length
    )
  )

  private isChecked = (item: ICheckedItem): boolean => (
     this.state.checkedIds.hasOwnProperty(item.index)
  )

  private toggleCheckedId = (item: ICheckedItem): void => {
    if (this.isChecked(item)) {
      const newState = Object.assign({}, this.state.checkedIds);
      delete newState[item.index];

      this.setState((prevState) => ({
        checkedIds: newState
      }));
    } else {
      this.setState((prevState) => ({
        checkedIds: {
          ...prevState.checkedIds,
          [item.index]: item.username,
        }
      }));
    }
  }


  /**
   * Render functions
   */
  protected renderInProgress(): JSX.Element {
    const { request } = this.props;

    if (request.data && request.data.length > 0) {
      return (
        <div className='request request--is-loading'>
          {this.renderSuccess(request.data, request.headers)}
        </div>
      );
    }

    return <ListSkeleton count={Config.PAGINATION_CAP} />;
  }
  protected renderFailure(): JSX.Element {
    return <div>failed to fetch</div>;
  }

  protected renderSuccess(data: IUser[], headers: IPaginationHeaders): JSX.Element {
    const listItems = data.map((user, index) => {
      const checkItem: ICheckedItem = {
        index,
        username: user.username
      };

      return (
        <InactiveUsersListItem
          key={index}
          user={user}
          itemNr={(index + 1)}
          checked={this.isChecked(checkItem)}
          onToggle={() => this.toggleCheckedId(checkItem)}
        />
      );
    });
    
    return <div>{listItems}</div>;
  }

  private renderDeleteButton(): JSX.Element {
    const styling = classnames({
      'btn--primary': true,
      'btn--request-states': typeof this.state.requestStatus !== 'undefined',
      'btn--spinner': this.state.requestStatus === RequestStatus.IN_PROGRESS,
      'btn--success': this.state.requestStatus === RequestStatus.SUCCESS,
      'btn--failed': this.state.requestStatus === RequestStatus.FAILED,
    });

    let icon = undefined;

    switch (this.state.requestStatus) {
      case RequestStatus.FAILED:
        icon = 'close';
        break;
      
      case RequestStatus.SUCCESS:
        icon = 'check';
        break;

      default: break;
    }

    return (
      <Button
        className={styling}
        disabled={Object.keys(this.state.checkedIds).length === 0 || typeof this.state.requestStatus !== 'undefined'}
        onClick={() => this.doDeleteUsers()}
        icon={icon}
        iconPosition='right'
      >{this.state.requestStatus === RequestStatus.SUCCESS ? 'Verwijderd' : 'Verwijderen'}</Button>
    );
  }

  public render(): JSX.Element {
    const { data, headers } = this.props.request;

    return (
      <ListPage>
        <ListHeader>
          <ListTitle quantity={(this.props.request.data && this.props.request.data.length) || 0}>Inactieve gebruikers</ListTitle>
          {this.props.authorized && (
            <ListNavItem>
              {this.renderDeleteButton()}
            </ListNavItem>
          )}
        </ListHeader>
        <ListBody>
          <InactiveUsersListHead
            onToggleAll={this.onToggleAll.bind(this)}
            toggleAllChecked={this.isEverythingChecked()}
          />
          {super.render()}
        </ListBody>
      </ListPage>
    );
  }
}
export default withRequest({
  url: '/users/stale',
  qs: fetchOptions,
}, withAuthorization(InactiveUsers));
import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

/* Data */
import { List, IFilterData } from 'data/lists/listsModels';

/* Redux */
import { IRootState } from 'store';
import { isChecked } from 'data/lists/listsSelectors';
import { toggleListItem } from 'data/lists/listsActions';

import SvgIcon from 'components/SvgIcon';


interface IStateToProps {
  yearFilterOptions: IFilterData[];
  isChecked(id: number): boolean;
}

interface IDispatchToProps {
  toggleOption(id: number): any;
}

const ReportsFilterYear: React.FC<IStateToProps & IDispatchToProps> = (props) => {
  const options = props.yearFilterOptions.map((option) => (
    <div key={option.id} className='filter__body-item'>
      <div className='radio radio--outline'>
        <input
          id={`filteryear-${String(option.id)}`}
          name='year'
          type='radio'
          checked={props.isChecked(option.id)}
          onChange={() => undefined}
          className='radio__input'
        />

        <label
          htmlFor={`filteryear-${String(option.id)}`}
          className='radio__label'
          onClick={() => props.toggleOption(option.id)}
        >
          <span className='radio__label-icon'>
            <SvgIcon icon='check' className='radio__label-img' />
          </span>
          <span className='radio__label-txt'>{option.label}</span>
        </label>
      </div>
    </div>
  ));

  return (
    <div className='filter__body'>
      {options}
    </div>
  );
};

const mapStateToProps = (state: IRootState): IStateToProps => ({
  yearFilterOptions: state.lists[List.Reports].year.data,
  isChecked: (id: number) => isChecked({
    list: List.Reports,
    group: 'year',
    id,
  })(state),
});

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => ({
  toggleOption: (id: number) => dispatch(toggleListItem({
    list: List.Reports,
    group: 'year',
    id,
  })),
});


export default connect(mapStateToProps, mapDispatchToProps)(ReportsFilterYear);

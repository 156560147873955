import * as React from 'react';
import RouteTabNavItem from './RouteTabNavItem';

import { routes } from 'services/routing';

import './TabNav.less';


const TabNav: React.FC = () => {
  return (
    <nav className='tabNav'>
      <RouteTabNavItem route={routes.Root}>Gebruikers</RouteTabNavItem>
      <RouteTabNavItem route={routes.Reports}>Rapportage</RouteTabNavItem>
      <RouteTabNavItem route={routes.InactiveUsers}>Inactieve gebruikers</RouteTabNavItem>
      <RouteTabNavItem route={routes.Exports}>Exports</RouteTabNavItem>
    </nav>
  );
};

export default TabNav;

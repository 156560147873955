import * as React from 'react';

import RequestComponent from './RequestComponent';
import { IPaginationHeaders, IPaginationOptions } from 'services/pagination';
import { RequestStatus } from 'data/request/requestModels';
import { IQueryStringOptions } from 'services/pagination';

import ListPagination from 'components/list/ListPagination';

export default abstract class PaginatedRequestComponent<P = {}, S = {}> extends RequestComponent<P, S> {
  protected abstract onPaginate(options: IPaginationOptions): any;

  public render(): React.ReactNode {
    const { headers } = this.props.request;

    return (
      <React.Fragment>
        {super.render()}
        <ListPagination
          currentPage={Number(headers['x-current-page']) || 0}
          total={Number(headers['x-total-count']) || 0}
          onPaginate={this.onPaginate.bind(this)}
        />
      </React.Fragment>
    );
  }
}

import { IAction, ActionType } from 'data/actions';

import * as filterMutators from './filterMutators';
import { IUserFilterOption, ITimefilterOption } from './filterModels';

export interface IFilterState {
  users: IUserFilterOption[];
  time: ITimefilterOption[];
}

export const initialState: IFilterState = {
  users: [],
  time: [],
};

const handlers: { [index: number]: any } = {
  [ActionType.SET_USER_FILTER_OPTIONS]: filterMutators.setUserFilterOptions,
  [ActionType.SET_TIMEFILTER_OPTIONS]: filterMutators.setTimefilterOptions,
};

export function filterReducer(state: IFilterState = initialState, action: IAction): IFilterState {
  if (handlers[action.type]) {
    return handlers[action.type](state, action.payload);
  }

  return state;
}
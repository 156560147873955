import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import SvgIcon from 'components/SvgIcon';
import { IToggleFilterPayload } from 'data/lists/listsModels';
import { toggleListItem } from 'data/lists/listsActions';


interface IProps {
  text: string;
  disablePayload?: IToggleFilterPayload;
  dismissable?: boolean;
}

interface IDispatchToProps {
  remove(): any;
}

const ListActiveFiltersItem: React.FC<IProps & IDispatchToProps> = ({
  text,
  remove,
  dismissable = false,
}) => (
  <button className='activeFilters__item' onClick={() => remove()}>
    <span className='activeFilters__item-label'>{text}</span>
    {dismissable && <span className='activeFilters__item-icon'>
      <SvgIcon icon='close' />
    </span>}
  </button>
);

const mapDispatchToProps = (dispatch: Dispatch, ownProps: IProps): IDispatchToProps => ({
  remove: () => (
    Boolean(ownProps.disablePayload) ? dispatch(toggleListItem(ownProps.disablePayload)) : undefined
  )
});

export default connect(undefined, mapDispatchToProps)(ListActiveFiltersItem);
/* tslint:disable:no-null-keyword */

import * as React from 'react';
import { Dispatch } from 'redux';
import { connect, DispatchProp } from 'react-redux';
import { IRootState } from 'store';
import { history, routes } from 'services/routing';

import { validateToken } from 'data/session/sessionActions';

import LocalStorageManager from 'services/LocalStorageManager';

interface IStateToProps {
  loggedIn: boolean;
}

interface IDispatchToProps {
  validateToken(): any;
}

interface IValidateRouteProps extends IStateToProps, IDispatchToProps { }

export function validateRoute<P>(
  Component: React.ComponentClass<P> | React.FC<P>
): React.ComponentClass<P> {
  class WrappedComponent extends React.Component<P & IValidateRouteProps & DispatchProp> {
    public componentWillMount(): void {
      const jwt = LocalStorageManager.getJwt();

      if (!this.props.loggedIn) {
        if (Boolean(jwt)) {
          this.props.validateToken();
        } else {
          history.push(routes.Login);
        }
      }
    }

    public render(): JSX.Element {
      if (!this.props.loggedIn) {
        return null;
      }

      return <Component {...this.props} />;
    }
  }

  const mapStateToProps = (state: IRootState, props: any): P => ({
    ...props,
    loggedIn: state.session.loggedIn,
  });

  const mapDispatchToProps = (dispatch: Dispatch, props: any): P => ({
    ...props,
    validateToken: () => dispatch(validateToken()),
  });

  return connect<P, P, any>(mapStateToProps, mapDispatchToProps)(WrappedComponent as any) as any;
}

export default validateRoute;

import * as React from 'react';
import * as classnames from 'classnames';
import SvgIcon from 'components/SvgIcon';


interface IProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  id: string; // Force to use an id
}

const Checkbox: React.FC<IProps> = ({ className, id, ...rest }) =>  {
  const styling = classnames({
    checkbox__input: true,
    [className]: Boolean(className),
  });

  return (
    <div className='checkbox'>
      <input id={id} type='checkbox' className={styling} {...rest} />
      <label htmlFor={id} className='checkbox__label'>
        <span className='checkbox__label-icon'>
          <SvgIcon className='checkbox__label-img' icon='check' />
        </span>
      </label>
    </div>
  );
};

export default Checkbox;
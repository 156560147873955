import * as React from 'react';

interface IProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  quantity?: number;
}

const ListTitle: React.FC<IProps> = ({ children, quantity }) => (
  <h1 className='list__title'>
    {children}
    {Boolean(quantity > 0) && <span className='list__title-qty'>({quantity})</span>}
  </h1>
);

export default ListTitle;

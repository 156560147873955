import * as React from 'react';
import Select from 'react-select';
import withRequestByProp from 'components/requests/requestByProp';
import RequestComponent from 'components/requests/RequestComponent';
import { RequestStatus } from 'data/request/requestModels';

import TimeFilter from './TimeFilter';

interface IProps {
  userId: number;
}

class UserListItemFilterTime extends RequestComponent<IProps> {
  protected renderFailure(): JSX.Element {
    return <div className='dateFilter__wrapper'>Fail</div>;
  }

  protected renderInProgress(): JSX.Element {
    return (
      <div className='dateFilter__wrapper'>
        <div className='dateFilter__description'>
          Deze gebruiker heeft toegang tot het internet van
        </div>
        <div className='dateFilter dateFilter--datetime'>
          <div className='dateFilter__item dateFilter__item--left'>
            <Select disabled placeholder='Data ophalen..' />
          </div>
          <div className='dateFilter__seperator'>tot</div>
          <div className='dateFilter__item dateFilter__item--right'>
            <Select disabled placeholder='Data ophalen..' />
          </div>
        </div>
      </div>
    );
  }

  protected renderSuccess(hours: number[]): JSX.Element {
    return <TimeFilter userId={this.props.userId} hours={hours} />;
  }
}


export default withRequestByProp({
  url: '/user/:userId/timefilters'
}, UserListItemFilterTime);

import { ActionType, IPureAction } from 'data/actions';
import { ISessionValues, IAuthenticated } from './sessionModels';
import { decodeJwt } from 'services/auth';

export const authenticationSuccess = (jwt: string): IPureAction<ActionType.LOGIN_SUCCESS, IAuthenticated> => ({
  type: ActionType.LOGIN_SUCCESS,
  payload: {
    jwt,
    jwtPayload: decodeJwt(jwt),
  },
});

export const logout = (): IPureAction<ActionType.LOGOUT, void> => ({
  type: ActionType.LOGOUT,
  payload: undefined,
});

export const validateToken = (): IPureAction<ActionType.VALIDATE_TOKEN, void> => ({
  type: ActionType.VALIDATE_TOKEN,
  payload: undefined,
});

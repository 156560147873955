import { IAction, ActionType } from 'data/actions';
import * as searchbarMutators from './searchbarMutators';

// export interface ISearchBarState {
//   [key: string]: string;
// }

// export interface ISearchBar {
//   value: string;
// }

// export const initialState: ISearchBarState = {
//   value: '',
// };

export const handlers: { [index: number]: any } = {
  [ActionType.SET_SEARCH_TERM]: searchbarMutators.setSearchbarValue,
};

export function searchbarReducer(state: string = '', action: IAction): string {
  if (handlers[action.type]) {
    return handlers[action.type](state, action.payload);
  }

  return state;
}
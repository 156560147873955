import { IOrganisation } from '../organisations/organisationsModels';

export enum List {
  Users = 'users',
  Archive = 'archive',
  Reports = 'reports',
}

// export interface IListWithId {
//   list: List;
//   id: number;
// }

// export interface IListFilters {
//   label: string;
//   data: any;
//   list?: List;
//   filter?: 'organisation' | 'date' | 'year' | 'active';
//   type?: 'checkbox' | 'radio' | 'date';
// }

export interface IFilter<I> {
  activeIds: number[];
  data: I[];
  type: FilterType;
}

export interface IFilterData {
  id: number;
  label: string;
  data: string | number; // Date to send?
}

export interface IListFilters {
  organisation?: IFilter<IOrganisation>;
  date?: IFilter<IFilterData>;
  activity?: IFilter<IFilterData>;
  year?: IFilter<IFilterData>;
}

/**
 * The FilterGroup type is used for toggling list items. An 
 * implementation can be found in listsActions.ts. 
 */
export type FilterGroup = 'organisation' | 'date' | 'activity' | 'year';
export type FilterType = 'checkbox' | 'radio' | 'date';

export interface IToggleFilterPayload {
  list: List;
  group: FilterGroup;
  id: number;
}

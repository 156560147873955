import * as React from 'react';
import * as classnames from 'classnames';
import { connect } from 'react-redux';

import { IRootState } from 'store';
import { fetchActiveFilters } from 'data/lists/listsSelectors';
import { List, IFilterData } from 'data/lists/listsModels';
import { IOrganisation } from 'data/organisations/organisationsModels';
import ListActiveFiltersItem from 'components/list/ListActiveFiltersItem';


interface IStateToProps {
  yearFilter: IFilterData;
}

class ActiveReportsFilter extends React.Component<IStateToProps> {
  public render(): JSX.Element {
    return (
      <div className='activeFilters'>
        <ListActiveFiltersItem text={this.props.yearFilter.label} />
      </div>
    );
  }
}


const mapStateToProps = (state: IRootState) => ({
  yearFilter: fetchActiveFilters(List.Reports, 'year')(state)[0],
});

export default connect(mapStateToProps)(ActiveReportsFilter);

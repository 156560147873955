import * as React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IRootState } from 'store';

/* Services / Data */
import { routes } from 'services/routing';
import { headerThemeCSS } from 'data/theme/themeSelectors';

/* Global components */
import privateComponent from 'components/privateComponent';

/* Nested components */
import HeaderUserDropdown from './components/HeaderUserDropdown';

/* Stylesheets */
import './Header.less';


const Header: React.FC = (props) => {
  const primaryTheme = useSelector<IRootState, React.CSSProperties>(headerThemeCSS);

  return (
    <header className='header'>
      <div className='header__top' style={primaryTheme}>
        <span className='header__top-title'>Internet op maat</span>
      </div>

      <div className='header__main'>
        <div className='header__main-logo'>
          <div className='headerLogo'>
            <Link to={routes.Root}>
              <img src='/config/images/logo.png' alt='logo' className='headerLogo__img' />
            </Link>
          </div>
        </div>
        <div className='header__main-nav'>
          <nav className='iconNav'>
            <HeaderUserDropdown />
          </nav>
        </div>
      </div>
    </header>
  );
};

export default privateComponent(Header);

import * as React from 'react';
import * as classnames from 'classnames';
import Select from 'react-select';

import Button from 'components/Button/Button';
import { RequestStatus } from 'data/request/requestModels';
import API from 'services/API';
import { calculateHoursBetween } from 'services/util';


const optionsFrom = Array.from([...Array(24)]).map((k, i) => ({
  value: i,
  label: `${i}:00`
}));

const optionsTo = Array.from([...Array(24)]).map((k, i) => ({
  value: i,
  label: `${i+1}:00`
}));

interface IProps {
  hours: number[];
  userId: number;
}

interface IState {
  from: number;
  to: number;
  modified: boolean;
  requestStatus: RequestStatus;
}

class TimeFilter extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    this.state = {
      from: props.hours[0],
      to: props.hours[props.hours.length - 1],
      modified: false,
      requestStatus: undefined,
    };
  }

  private submit(): void {
    this.setState({ requestStatus: RequestStatus.IN_PROGRESS }, () => {
      this.doRequest(JSON.stringify(calculateHoursBetween(this.state.from, this.state.to)));
    });
  }

  private doRequest = async (payload: string): Promise<void> => {
    try {
      const response = await API.put(`/user/${this.props.userId}/timefilters`, payload);
      this.setState({
        requestStatus: RequestStatus.SUCCESS,
      });
    } catch (err) {
      this.setState({ requestStatus: RequestStatus.FAILED });
    }
  }

  public render(): JSX.Element {
    const styling = classnames({
      dateFilter__wrapper: true,
      loading: this.state.requestStatus === RequestStatus.IN_PROGRESS,
      error: this.state.requestStatus === RequestStatus.FAILED,
      success: this.state.requestStatus === RequestStatus.SUCCESS,
    });

    return (
      <div className={styling}>
        <div className='dateFilter__description'>
          Deze gebruiker heeft toegang tot het internet van
        </div>
        <div className='dateFilter dateFilter--dateTime'>
          <div className='dateFilter__item dateFilter__item--left'>
            <Select
              clearable={false}
              value={`${this.state.from}`}
              options={optionsFrom}
              onChange={(option: { value: number })  => this.setState({ from: option.value, modified: true })}
            />
          </div>
          <div className='dateFilter__seperator'>tot</div>
          <div className='dateFilter__item dateFilter__item--right'>
            <Select
              clearable={false}
              value={`${this.state.to}`}
              options={optionsTo}
              onChange={(option: { value: number }) => this.setState({ to: option.value, modified: true })}
            />
          </div>
        </div>
        <div className='dateFilter__actions'>
          <Button
            className='btn--primary dateFilter__action'
            icon={this.state.requestStatus === RequestStatus.SUCCESS ? 'check' : undefined}
            iconPosition='right'
            iconClass='dateFilter__action-icon'
            disabled={!this.state.modified || this.state.requestStatus === RequestStatus.IN_PROGRESS}
            onClick={this.submit.bind(this)}
          >{this.state.requestStatus === RequestStatus.SUCCESS ? 'Opgeslagen' : 'Opslaan'}</Button>
        </div>
      </div>
    );
  }
}

export default TimeFilter;

import * as reselect from 'reselect';
import { IRootState } from 'store';
import { List, IToggleFilterPayload, FilterGroup, IFilter } from 'data/lists/listsModels';

export const fetchListContents = (list: List) => reselect.createSelector(
  (state: IRootState) => state.lists[list],
  (listContent) => listContent,
);

export const isChecked = (payload: IToggleFilterPayload) => reselect.createSelector(
  (state: IRootState) => state.lists[payload.list][payload.group].activeIds,
  (activeIds: number[]) => Boolean(
    activeIds.find((id) => id === payload.id),
  )
);

export const fetchActiveFilters = (list: List, group: FilterGroup) => reselect.createSelector(
  (state: IRootState) => state.lists[list][group],
  (filter: IFilter<any>) => {
    const response: any[] = [];
    
    filter.activeIds.forEach((activeId) => {
      const found = filter.data.find((filterItem) => filterItem.id === activeId);

      if (found) {
        response.push(found);
      }
    });

    return response;
  }
);

import { IListsState } from './listsReducer';
import { List, IListFilters, IToggleFilterPayload } from './listsModels';
import { IOrganisation } from '../organisations/organisationsModels';


export const fillOrganisationFilterData = (state: IListsState, payload: IOrganisation[]): IListsState => ({
  ...state,
  [List.Users]: {
    ...state[List.Users],
    organisation: {
      ...state[List.Users]['organisation'],
      data: payload,
    },
  },
});

const handleCheckboxToggle = (state: IListsState, payload: IToggleFilterPayload): IListsState => {
  const { activeIds } = state[payload.list][payload.group];
  let newActiveIds = [];

  if (Boolean(activeIds.find((activeId) => activeId === payload.id))) {
    newActiveIds = activeIds.filter((activeId) => activeId !== payload.id);
  } else {
    newActiveIds = activeIds.concat(payload.id);
  }

  return {
    ...state,
    [payload.list]: {
      ...state[payload.list],
      [payload.group]: {
        ...state[payload.list][payload.group],
        activeIds: newActiveIds,
      },
    },
  };
};

const handleRadioToggle = (state: IListsState, payload: IToggleFilterPayload): IListsState => ({
  ...state,
  [payload.list]: {
    ...state[payload.list],
    [payload.group]: {
      ...state[payload.list][payload.group],
      activeIds: [payload.id],
    },
  },
});

export const setToggleListItem = (state: IListsState, payload: IToggleFilterPayload): IListsState => {
  const listType = state[payload.list][payload.group].type;
  
  if (listType === 'checkbox') {
    return handleCheckboxToggle(state, payload);
  }

  return handleRadioToggle(state, payload);
};
import * as React from 'react';
import Button from 'components/Button/Button';

import './ListActionDropdown.less';


interface IProps {
  children: JSX.Element;
  buttonStyling?: string;
}

interface IState {
  active: boolean;
}

class ListActionDropdown extends React.Component<IProps, IState> {
  private wrapper: React.RefObject<HTMLDivElement> = React.createRef();
  public state: IState = {
    active: false,
  };

  private toggle = (): void => this.setState({ active: !this.state.active });

  public handleClickOutside(event: React.MouseEvent<HTMLElement>): void {
    if (Boolean(this.wrapper.current) && !this.wrapper.current.contains(event.target as Node) && this.state.active) {
      this.toggle();
    }
  }

  public componentDidMount(): void {
    window.addEventListener('mousedown', this.handleClickOutside.bind(this));
  }

  public componentWillUnmount(): void {
    window.removeEventListener('mousedown', this.handleClickOutside.bind(this));
  }


  public render(): JSX.Element {
    return (
      <div className={`actionBtn__wrapper ${this.state.active ? 'is-open' : ''}`} ref={this.wrapper}>
        <Button className={`actionBtn ${this.props.buttonStyling}`} onClick={this.toggle.bind(this)}>
          <span className='actionBtn__icon'>
            <span/><span/><span/>
          </span>
        </Button>
        {this.state.active && (
          <div className='actionBtn__dropdown'>
            {this.props.children}
          </div>
        )}
      </div>
    );
  }
}

export default ListActionDropdown;

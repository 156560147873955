import * as React from 'react';
import { IReports } from 'data/reports/reportsModels';

interface IProps {
  header: any[];
}

const ReportsListHead: React.FC<IProps> = ({ header }) => {
  const headerColumns = header.map((col: string | number, index: number) => (
    <div key={index} className='list__head-col'>
      {col}
    </div>
  ));

  return (
    <li className='list__head list__head--reports'>
      {headerColumns}
    </li>
  );
};

export default ReportsListHead;
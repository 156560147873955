import { ActionType, IPureAction } from 'data/actions';
import { IUserFilterOption, ITimefilterOption } from './filterModels';

export const setUserFilterOptions = (options: IUserFilterOption[]): IPureAction<ActionType.SET_USER_FILTER_OPTIONS, IUserFilterOption[]> => ({
  type: ActionType.SET_USER_FILTER_OPTIONS,
  payload: options,
});

export const setTimefilterOptions = (options: ITimefilterOption[]): IPureAction<ActionType.SET_TIMEFILTER_OPTIONS, ITimefilterOption[]> => ({
  type: ActionType.SET_TIMEFILTER_OPTIONS,
  payload: options,
});
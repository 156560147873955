import * as React from 'react';
import * as classnames from 'classnames';

import Button from 'components/Button/Button';

import '../../list/ListActionDropdown/ListActionDropdown.less';

interface IProps {
  // dropdownComponent: JSX.Element;
  buttonText: string;
  buttonStyling?: string;
  wrapper?: any;
}

interface IState {
  active: boolean;
}

class FilterActionDropdown extends React.Component<IProps, IState> {
  public state: IState = {
    active: false,
  };

  private handleClick(event: any): void {
    this.setState({ active: !this.state.active });
  }

  public handleClickOutside(event: React.MouseEvent<HTMLElement>): void {
    if (this.wrapper !== null && !this.wrapper.contains(event.target) && this.state.active) {
      this.setState({
        active: !this.state.active
      });
    }
  }

  private wrapper: any;

  public setWrapper(wrapper: HTMLElement): void { this.wrapper = wrapper; }

  public componentWillMount(): void {
    document.addEventListener('mousedown', this.handleClickOutside.bind(this));
  }

  public componentWillUnmount(): void {
    document.removeEventListener('mousedown', this.handleClickOutside.bind(this));
  }

  public render(): JSX.Element {
    return (
      <div className={`actionBtn__wrapper ${this.state.active ? 'is-open' : ''}`} ref={this.setWrapper.bind(this)}>
        <Button className={`actionBtn ${this.props.buttonStyling}`} onClick={this.handleClick.bind(this)} icon={this.state.active ? 'close' : 'angle-down'} iconClass='btn__icon actionBtn__icon' iconPosition='right'>
          {this.props.buttonText}
        </Button>
        <div className='actionBtn__dropdown'>
          <div className='dropFilter'>
            {this.state.active && this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

export default FilterActionDropdown;

import * as React from 'react';
import { Redirect } from 'react-router';
import { useSelector } from 'react-redux';

import LoginForm from 'scenes/Login/components/LoginForm';

import SvgIcon from 'components/SvgIcon';
import { IRootState } from 'store';
import { isLoggedIn } from 'data/session/sessionSelectors';
import { routes } from 'services/routing';

import './Login.less';


const Login: React.FC = () => {
  const loggedIn = useSelector<IRootState, boolean>(isLoggedIn);

  if (loggedIn) {
    return <Redirect to={routes.Root} />;
  }

  return (
    <div className='login'>
      <div className='login__logo-wrapper'>
        <SvgIcon className='login__logo' icon='logo' />
      </div>
      <h1 className='login__title'>Internet op maat</h1>
      <div className='login__inner'>
        <LoginForm />
      </div>
    </div>
  );
};

export default Login;
import * as React from 'react';
import { IRequestProps } from 'components/requests/request';
import { RequestStatus, IRequestOptions } from 'data/request/requestModels';
import { IQueryStringOptions } from 'services/pagination';

export default abstract class RequestComponent<P = {}, S = {}> extends React.Component<P & IRequestProps, S> {
  protected abstract renderInProgress(): React.ReactNode;
  protected abstract renderSuccess(data: any, headers: any): React.ReactNode;
  protected abstract renderFailure(): React.ReactNode;

  protected doRequest(queryStringOptions: IQueryStringOptions): void {
    this.props.doRequest({
      url: this.props.options.url,
      qs: queryStringOptions,
    });
  }

  public render(): React.ReactNode {
    switch (this.props.request.requestStatus) {
      case RequestStatus.IN_PROGRESS: return this.renderInProgress();
      case RequestStatus.SUCCESS: return this.renderSuccess(this.props.request.data, this.props.request.headers);
      case RequestStatus.FAILED: return this.renderFailure();
      default: throw new Error('Something went wrong, got no requestStatus');
    }
  }
}

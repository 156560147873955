import { AxiosResponse, AxiosError } from 'axios';
import { call, put } from 'redux-saga/effects';

/* Services */
import API from 'services/API';

/* Data */
import { IPureAction, ActionType } from 'data/actions';
import { logout } from 'data/session/sessionActions';
import { IAuthenticated } from 'data/session/sessionModels';
import { setOrganisations } from './organisationsActions';


export function* fetchOrganisations(action: IPureAction<ActionType.LOGIN_SUCCESS, IAuthenticated>): any {
  try {
    const response: AxiosResponse = yield call(API.fetch, 'organisations');
    yield put(setOrganisations(response.data));
  } catch (err) {
    yield put(logout());
  }
}

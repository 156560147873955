import * as React from 'react';
import * as classnames from 'classnames';

import Checkbox from 'components/Checkbox/Checkbox';
import ListHead from 'components/list/ListHead';
import { List } from 'data/lists/listsModels';
import SvgIcon from 'components/SvgIcon';
import withAuthorization, { InjectedAuthProps } from 'components/hoc/withAuthorization';

interface IProps  {
  onSort(column: string, direction: 'asc' | 'desc'): any;
  sortColumn: string;
  sortDirection: 'asc' | 'desc';
}

interface IState {
  column: string;
  direction: 'asc' | 'desc';
}

class UsersListHead extends React.Component<IProps & InjectedAuthProps, IState> {
  public constructor(props: IProps & InjectedAuthProps) {
    super(props);

    this.state  = {
      column: props.sortColumn,
      direction: props.sortDirection,
    };
  }

  private toggleDirection = () => (
    this.state.direction === 'asc' ? 'desc' : 'asc'
  )

  private handleHeadClick(column: string): void {
    this.setState({
      column,
      direction: this.state.column === column ? this.toggleDirection() : 'asc',
    });
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState): void {
    if (this.state !== prevState) {
      this.props.onSort(this.state.column, this.state.direction);
    }
  }

  private sortableColumn(column: string, label: string, spanWidth: number): JSX.Element {
    const asc = this.state.column === column && this.state.direction === 'asc';
    const desc = this.state.column === column && this.state.direction === 'desc';

    const styling = classnames({
      'list__head-col': true,
      'list__head-sortable': true,
      [`span-${spanWidth}`]: true,
    });

    return (
      <div className={styling} onClick={() => this.handleHeadClick(column)}>
        <span className='list__head-sortable__arrows'>
          <SvgIcon className={`list__head-sortable__arrow ${asc ? 'active' : ''}`} icon='angle-up' />
          <SvgIcon className={`list__head-sortable__arrow ${desc ? 'active' : ''}`} icon='angle-down' />
        </span>
        <span className='list__head-sortable__title'>
          {label}
        </span>
      </div>
    );
  }

  public render(): JSX.Element {
    const { onSort, authorized } = this.props;

    return (
      <ListHead>
        <div className='list__head-col span-5'>#</div>
        {this.sortableColumn('lastname', 'Naam', 23)}
        {this.sortableColumn('location', 'Locatie', 22)}
        {this.sortableColumn('organisation', 'Organisatie', 13)}
        {this.sortableColumn('username', 'Gebruikersnaam', 17)}
        {this.sortableColumn('registration_date', 'Registratie', 12)}
        {this.sortableColumn('last_seen', 'Laatst actief', 12)}
        {authorized && <div className='list__head-col span-5' />}
      </ListHead>
    );
  }
}

export default withAuthorization(UsersListHead);

import * as React from 'react';
import API from 'services/API';

interface IStateToProps {
}

class ExportActiveUsers extends React.Component<IStateToProps> {
  private downloadCSV(year: number, month: number): void {
    API.download(`/exports/users/active/${year}/${month}`, `active-users-${year}-${month}.csv`, 'text/csv');    
  }

  public render(): JSX.Element {
    const rows: JSX.Element[] = [];
    const startYear = 2017;
    const endYear = new Date().getFullYear();
    const endMonth = new Date().getMonth();
    const numberOfMonths = 12;
    const monthNames = ['jan', 'feb', 'mar', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'];

    for(let year = startYear; year <= endYear; year++) {
      const row: JSX.Element[] = [];
      row.push(<div className='list__item-col' key={year}>{year}</div>);

      for(let month = 1; month <= numberOfMonths; month++) {
        if(year === endYear && month > endMonth) {
          row.push(<div className='list__item-col list__item-col--disabled' key={month}>{monthNames[month-1]}</div>);
          continue;
        } 

        row.push(<div className='list__item-col' key={month}><a onClick={this.downloadCSV.bind(this, year, month)}>{monthNames[month-1]}</a></div>);
      } 

      rows.push(<li className='list__item list__item--reports' key={year}>{row}</li>);
    }

    return (
      <div className='report report--no-total'>
        <ul className='list__body reports'>
          <h3 className='report__title'>Actieve gebruikers per maand</h3>
          <div className='report__content'>
          {rows}
          </div>
        </ul>
      </div>
    );
  }
}

export default ExportActiveUsers;
import createHistory from 'history/createBrowserHistory';

export interface IRoutes {
  [index: string]: string;
}

export const routes: IRoutes = {
  Root: `${BASE_URL}`,
  Reports: `${BASE_URL}reports`,
  Exports: `${BASE_URL}exports`,
  Login: `${BASE_URL}login`,
  InactiveUsers: `${BASE_URL}inactive-users`,
};

export const history = createHistory();

import * as React from 'react';
import * as moment from 'moment';

interface IProps {
  time: string | null;
  format: string;
}

const MomentDate: React.FC<IProps> = ({ time, format }) => {
  const formatted = time ? moment(new Date(time)).format(format) : '-';

  return <span>{formatted}</span>;
};

export default MomentDate;

import * as React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

/* Data */
import { IRootState } from 'store';
import { IUser } from 'data/users/usersModels';
import { IUserFilterOption } from 'data/filters/filterModels';

/* Generic components */
import RequestComponent from 'components/requests/RequestComponent';
import withRequestByProp from 'components/requests/requestByProp';

/* Nested components */
import UserListItemFilterItem from './UserListItemFilterItem';

/* Stylesheet */
import './UserListItemAction.less';


interface IStateToProps {
  options: IUserFilterOption[];
}

interface IProps {
  id: number;
}

type Props = IProps & IStateToProps;

class UserListItemFilter extends RequestComponent<Props> {
  protected renderInProgress(): JSX.Element {
    return (
      <ul className='dropFilter__list dropFilter__list--skeleton'>
        {Array.from([...Array(this.props.options.length || 3)]).map((k, i) => (
          <li key={i} className='dropFilter__item'><span /></li>)
        )}
      </ul>
    );
  }

  protected renderFailure(): JSX.Element {
    return <div>failed to fetch</div>;
  }

  protected renderSuccess(data: IUserFilterOption[]): any {
    return (
      <ul className='dropFilter__list'>
        {this.renderOptionsList()}
      </ul>
    );
  }

  private renderOptionsList = (): JSX.Element[] => (
    this.props.options.map((option) => (
      <UserListItemFilterItem
        key={option.id}
        userId={this.props.id}
        active={this.isFilterActive(option.id)}
        filterOption={option}
      />
    ))
  )

  private isFilterActive = (filterId: number): boolean => (
    this.props.request.data.findIndex((item: IUserFilterOption) => item.id === filterId) >= 0
  )

  public render(): JSX.Element {
    const { data } = this.props.request;
  
    return (
      <div className='dropFilter__list-wrapper'>
        {super.render()}
      </div>
    );
  }
}


const mapStateToProps = (state: IRootState): IStateToProps => ({
  options: state.filters.users,
});

export default withRequestByProp<IProps, void>({
  url: '/user/:id/filters'
}, connect(mapStateToProps)(UserListItemFilter));

import * as React from 'react';

import { ReportType } from 'data/reports/reportsModels';

/* Generic components */
import ListPage from 'components/list/ListPage/ListPage';
import ListHeader from 'components/list/ListHeader';
import ListTitle from 'components/list/ListTitle';
import ExportActiveUsers from './components/ExportActiveUsers';

import './Exports.less';

class Exports extends React.Component {
  public render(): JSX.Element {
    return (
      <ListPage>
        <ListHeader>
          <ListTitle>Exports</ListTitle>
        </ListHeader>
        <ExportActiveUsers />
      </ListPage>
    );
  }
}

export default Exports;

import * as React from 'react';
import { IReports } from 'data/reports/reportsModels';

interface IProps {
  rows: Array<any>;
}

const ReportsList: React.FC<IProps> = ({ rows }) => {
  const listItems = rows.map((row, index) => (
    <li key={index} className="list__item list__item--reports">
      {row.map((col: number | string, nestedIndex: number) => (
        <div key={nestedIndex} className='list__item-col'>
          {col}
        </div>
      ))}
    </li>
  ))

  return (
    <div>{listItems}</div>
  );
};

export default ReportsList;
import * as React from 'react';
import * as classnames from 'classnames';
import { connect } from 'react-redux';

/* Data */
import { IRootState } from 'store';
import { IReports } from 'data/reports/reportsModels';
import { ReportType } from 'data/reports/reportsModels';
import { fetchActiveFilters } from 'data/lists/listsSelectors';
import { List } from 'data/lists/listsModels';

/* Request-related */
import withRequestByProp from 'components/requests/requestByProp';
import { IRequestProps } from 'components/requests/request';
import RequestComponent from 'components/requests/RequestComponent';

/* Generic components */
import ListSkeleton from 'components/list/ListSkeleton';

/* Nested components */
import ReportsListHead from './components/ReportsListHead';
import ReportsList from './components/ReportsList';
import ReportTotals from './components/ReportTotals';

/* Stylesheet */
import './Report.less';


interface IStateToProps {
  year: number;
}

interface IProps extends IStateToProps {
  title: string;
  type: ReportType;
  className?: string;
}

class Report extends RequestComponent<IProps> {
  protected renderFailure(): JSX.Element {
    return <div className='list__empty'>Er konden geen gegevens opgehaald worden.</div>;
  }

  protected renderInProgress(): JSX.Element {
    return <ListSkeleton count={4} />;
  }

  protected renderSuccess(data: IReports): JSX.Element {
    if (data && !data.rows) {
      return <div className='list__empty'>Er zijn geen rapportages om te weergeven.</div>;
    }

    return (
      <div className='report__content'>
        <ReportsListHead header={data.header} />
        <ReportsList rows={data.rows} />
        <ReportTotals totals={data.totals} />
      </div>
    );
  }

  public render(): JSX.Element {
    const styling = classnames({
      report: true,
      [this.props.className]: Boolean(this.props.className),
    });

    return (
      <div className={styling}>
        <h3 className='report__title'>{this.props.title}</h3>
        {super.render()}
      </div>
    );
  }
}

const mapStateToProps = (state: IRootState): IStateToProps => ({
  year: fetchActiveFilters(List.Reports, 'year')(state)[0].data,
});

export default connect(mapStateToProps)(withRequestByProp({
  url: '/reports/totals/:type/:year',
}, Report));

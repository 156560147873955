import * as React from 'react';
import { AxiosResponse, AxiosError } from 'axios';

import { IRequestOptions, RequestStatus, RequestHoC } from 'data/request/requestModels';
import API from 'services/API';
import { constructQuerystring } from 'services/pagination';

interface IEnhancerState {
  requestStatus: RequestStatus;
  data?: any;
  headers: any;
  error?: AxiosError;
  options: IRequestOptions;
}

export interface IRequestProps {
  request?: IEnhancerState;
  doRequest?(options: IRequestOptions): void;
  options?: IRequestOptions;
}

function withRequest<P, S>(
  options: IRequestOptions,
  Component: RequestHoC<P>,
): React.ComponentClass<P> {

  class Enhancer extends React.Component<P, IEnhancerState> {
    public state: IEnhancerState = {
      requestStatus: RequestStatus.IN_PROGRESS,
      headers: {},
      options,
    };

    public componentWillMount(): void {
      this.performRequest(options);
    }

    private performRequest(opts: IRequestOptions): void {
      this.setState((prevState) => ({
        ...prevState,
        requestStatus: RequestStatus.IN_PROGRESS,
      }), () => {
        API.fetch(`${opts.url}${constructQuerystring(opts.qs)}`)
        .then(this.handleSuccess.bind(this))
        .catch(this.handleFailure.bind(this));
      });
    }

    private handleSuccess(response: AxiosResponse): void {
      this.setState({
        requestStatus: RequestStatus.SUCCESS,
        data: response.data,
        headers: response.headers,
      });
    }

    private handleFailure(error: AxiosError): void {
      this.setState({
        requestStatus: RequestStatus.FAILED,
        error,
      });
    }

    public render(): JSX.Element {
      return (
        <Component
          {...this.props}
          request={this.state}
          options={options}
          doRequest={this.performRequest.bind(this)}
        />
      );
    }
  }

  return Enhancer;
}

export default withRequest;
import { Action } from 'redux';

export interface IAction extends Action<ActionType> {
  payload: any;
}

export interface IPureAction<T, P> {
  type: T;
  payload: P;
}

export enum ActionType {
  // Lists
  TOGGLE_LIST_ITEM,

  // SearchBar
  SET_SEARCH_TERM,

  // Sessions
  LOGIN_SUCCESS,
  LOGOUT,
  VALIDATE_TOKEN,

  // Organisations
  SET_ORGANISATIONS,

  // Filters
  SET_USER_FILTER_OPTIONS,
  SET_TIMEFILTER_OPTIONS,
}
import { IAction } from 'data/actions';

interface IThemeSet {
  background: string;
  text: string;
}

export interface IThemeState {
  header: IThemeSet;
  primary: IThemeSet;
  secondary: IThemeSet;
}

export const initialState: IThemeState = {
  header: {
    background: '#333333',
    text: 'white'
  },
  primary: {
    background: '#01B2D6',
    text: 'white',
  },
  secondary: {
    background: '#F4B932',
    text: '#323232'
  }
};

const handlers: { [index: number]: any } = {
};

export function themeReducer(state: IThemeState = initialState, action: IAction): IThemeState {
  if (handlers[action.type]) {
    return handlers[action.type](state, action.payload);
  }

  return state;
}

import * as React from 'react';
import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { reduxForm, Field, FormErrors, InjectedFormProps } from 'redux-form';

/* Generic components */
import SpinnerButton from 'components/SpinnerButton/SpinnerButton';

/* Nested components */
import LoginInputWrapper from './LoginInputWrapper';

/* Data */
import { ISessionValues, IAuthenticated } from 'data/session/sessionModels';
import { authenticationSuccess } from 'data/session/sessionActions';
import API from 'services/API';
import LocalStorageManager from 'services/LocalStorageManager';


interface IFormData extends ISessionValues {}

type Props = InjectedFormProps<IFormData>;

const LoginFormRxForm: React.FC<Props> = (props) => (
  <form className='login__form' onSubmit={props.handleSubmit}>
    <Field
      label='E-mail'
      id='username'
      name='username'
      type='input'
      component={LoginInputWrapper}
      className='login__input'
    />

    <Field
      label='Wachtwoord'
      id='password'
      name='password'
      type='password'
      component={LoginInputWrapper}
      className='login__input'
    />

    {props.submitFailed && (
      <div className='login__message--error'>
        Het inlogverzoek is mislukt. Controleer uw inloggegevens.
      </div>
    )}

    <SpinnerButton activate={props.submitting} type='submit' className='login__submit'>
      Inloggen
    </SpinnerButton>
  </form>
);


/* redux-form onSubmit handler */
const handleLogin = async (values: IFormData, dispatch: Dispatch): Promise<void> => {
  const response: AxiosResponse<IAuthenticated> = await API.post('login', values);

  LocalStorageManager.setJwt(response.data.jwt);
  API.refreshToken();

  dispatch(authenticationSuccess(response.data.jwt));
};

/* redux-form validation */
const validate = (values: Readonly<IFormData>): FormErrors<IFormData> => {
  const errors: FormErrors<IFormData> = {};

  if (values.username === undefined) {
    errors.username = 'Vul een geldig e-mailadres in';
  }

  if (values.password === undefined) {
    errors.password = 'Vul een wachtwoord in';
  }

  return errors;
};

export default reduxForm({
  form: 'login',
  onSubmit: handleLogin,
  validate,
})(LoginFormRxForm);

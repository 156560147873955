import * as React from 'react';
import ListNavItem from './ListNavItem';

interface IProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const ListNav: React.FC<IProps> = ({ children }) => (
  <div className='list__nav'>
    {children}
  </div>
);

export default ListNav;

import * as React from 'react';
import * as classnames from 'classnames';

import SvgIcon from 'components/SvgIcon';
import API from 'services/API';
import { IUserFilterOption } from 'data/filters/filterModels';
import { AxiosPromise } from 'axios';
import { RequestStatus } from 'data/request/requestModels';

interface IProps {
  userId: number;
  active: boolean;
  filterOption: IUserFilterOption;
}

interface IState {
  requestStatus: RequestStatus;
  active: boolean;
}

class UserListItemFilterItem extends React.Component<IProps, IState> {
  private apiPath: string;

  public constructor(props: IProps) {
    super(props);
    this.apiPath = `user/${props.userId}/filter/${props.filterOption.id}`;

    this.state = {
      requestStatus: undefined,
      active: props.active,
    };
  }

  private handleClick(): any {
    this.setState({ requestStatus: RequestStatus.IN_PROGRESS }, () => {
      this.doRequest(this.state.active ? API.delete : API.put);
    });
  }

  private async doRequest(apiMethod: (url: string) => AxiosPromise): Promise<void> {
    try {
      const response = await apiMethod(this.apiPath);
      this.setState({
        requestStatus: RequestStatus.SUCCESS,
        active: !this.state.active,
      });
    } catch (err) {
      this.setState({ requestStatus: RequestStatus.FAILED });
    }
  }

  public render(): JSX.Element {
    const styling = classnames({
      dropFilter__item: true,
      active: this.state.active,
      loading: this.state.requestStatus === RequestStatus.IN_PROGRESS,
      error: this.state.requestStatus === RequestStatus.FAILED,
    });

    return (
      <li className={styling} onClick={this.handleClick.bind(this)}>
        <span>{this.props.filterOption.name}</span>
        <span className='dropFilter__item-icon'>
          <SvgIcon icon='check' />
        </span>
      </li>
    );
  }
}

export default UserListItemFilterItem;
/**
 * Check if obj2 contains all keys present in obj1 
 * @param obj1 
 * @param obj2 
 */
export function objectContainsKeys(obj1: any, obj2: any): boolean {
   return Object.keys(obj1).some((r) => Object.keys(obj2).indexOf(r) >= 0);
}

export const hexToRgba = (hex: string, opacity: number) => {
  const rgba = hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => `X${r}${r}${g}${g}${b}${b}`)
  .substring(1).match(/.{2}/g)
  .map((x) => parseInt(x, 16));

  return `rgba(${rgba[0]}, ${rgba[1]}, ${rgba[2]}, ${opacity})`;
};

export function calculateHoursBetween(from: number, to: number): number[] {
  const array = [];
  const hours = 24;

  if (from > to) {
    let betweenIterator = 0;
    let restIterator = JSON.parse(JSON.stringify(from));

    while (restIterator < hours) {
      array.push(restIterator++);
    }

    while (betweenIterator < to) {
      array.push(betweenIterator++);
    }
  } else if (to > from) {
    let iterator = JSON.parse(JSON.stringify(from));

    while (iterator <= to) {
      array.push(iterator++);
    }
  } else {
    let iterator = 0;

    while (iterator < hours) {
      array.push(iterator++);
    }
  }

  return array.sort((a, b) => (a - b));
}
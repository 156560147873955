import { IAction, ActionType } from 'data/actions';

import * as sessionMutators from './sessionMutators';
import { IjwtPayload } from 'services/auth';

export interface ISessionState {
  loggedIn: boolean;
  userData: IjwtPayload;
}

export const initialState: ISessionState = {
  loggedIn: false,
  userData: undefined,
};

const handlers: { [index: number]: any } = {
  [ActionType.LOGIN_SUCCESS]: sessionMutators.setLoginSuccess,
  [ActionType.LOGOUT]: sessionMutators.setLoggedOut,
};

export function sessionReducer(state: ISessionState = initialState, action: IAction): ISessionState {
  if (handlers[action.type]) {
    return handlers[action.type](state, action.payload);
  }

  return state;
}
import { AxiosResponse, AxiosError } from 'axios';
import { call, put } from 'redux-saga/effects';

import API from 'services/API';
import LocalStorageManager from 'services/LocalStorageManager';

/* Data */
import { IPureAction, ActionType } from 'data/actions';
import { IAuthenticated } from './sessionModels';
import { authenticationSuccess, logout } from './sessionActions';
 
/* Routes */
import { history, routes } from 'services/routing';


export function* performTokenValidation(action: IPureAction<ActionType.VALIDATE_TOKEN, void>): any {
  try {
    const response: AxiosResponse = yield call(API.fetch, 'refresh-token');
    yield put(authenticationSuccess(response.data.refresh_token));
  } catch (err) {
    yield put(logout());
  }
}


/**
 * Push the user to the dashboard upon signing in.
 * If the user is currently on the login page, it gets
 * pushed to the users list page.
 *
 * @param  action  Redux action
 */
export function* uponAuthenticated(action: IPureAction<ActionType.LOGIN_SUCCESS, IAuthenticated>): any {
  if (history.location.pathname === routes.Login) {
    history.push(routes.Root);
  }
}


/**
 * When a user logs out, this clears the JWT from the browser's 
 * localStorage, removes the JWT from the Axios instance's headers
 * and pushes the user to the login screen.
 * 
 * @param action Redux action
 */
export function* uponLogout(action: any): any {
  LocalStorageManager.removeJwt();
  API.removeToken();
  history.push(routes.Login);
}
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import RootApp from 'scenes/RootApp/RootApp';
import { store } from 'store';


/* DOM element to render the application within */
const target = document.getElementById('root');

/**
 * Renders the main application.
 */
ReactDOM.render(
  <Provider store={store}>
    <RootApp />
  </Provider>,
  target,
);


import * as React from 'react';
import * as classnames from 'classnames';

interface IProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  width?: number;
}

const ListItemColumn: React.FC<IProps> = ({ children, width, className, ...rest }) => {
  const styling = classnames({
    'list__item-col': true,
    [className]: Boolean(className),
    [`span-${width}`]: width !== undefined,
  });

  return (
    <div className={styling} {...rest}>
      {children}
    </div>
  );
};

export default ListItemColumn;

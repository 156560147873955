import * as React from 'react';
import { IReports } from 'data/reports/reportsModels';

interface IProps {
  totals: number[];
}

const ReportTotals: React.FC<IProps> = ({ totals }) => {
  const columns: JSX.Element[] = [];

  columns.push(<div key='title' className='list__item-col'>Totaal</div>);

  totals.forEach((col: string | number, index: number) => columns.push(
    <div key={index} className='list__item-col'>
      {col}
    </div>
  ));

  return (
    <li className='list__item list__item--footer list__item--reports'>
      {columns}
    </li>
  );
};

export default ReportTotals;
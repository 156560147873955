import * as React from 'react';

import UserListItemFilterTime from './UserListItemFilterTime';
import UserListItemFilterList from './UserListItemFilterList';
import UserListItemDropfilter from './UserListItemDropfilter';

import './UserListItemAction.less';


interface IProps {
  id: number;
}

/**
 * Add the class 'dropFilter__component--collapsed to get a plus icon
 */

const UserListItemFilter: React.FC<IProps> = ({ id }) => {
  return (
    <div className='dropFilter dropFilter--user'>
      <UserListItemDropfilter title='Categorie' open>
        <UserListItemFilterList id={id} />
      </UserListItemDropfilter>
      <UserListItemDropfilter title='Tijdsbeperking'>
        <UserListItemFilterTime userId={id} />
      </UserListItemDropfilter>
    </div>
  );
};

export default UserListItemFilter;

import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { debounce } from 'throttle-debounce';

import { IRootState } from 'store';
import { setSearchValue } from 'data/searchbar/searchbarActions';

import './SearchBar.less';
import SvgIcon from 'components/SvgIcon';


interface IDispatchToProps {
  search(value: string): void;
}

interface IState {
  value: string;
}

interface IProps {
  searchterm: string;
}

class SearchBar extends React.Component<IProps & IDispatchToProps & RouteComponentProps<any>> {
  public state: IState = {
    value: ''
  };

  /** 
   * Set the input value
   */
  public handleInputChange(event: React.FormEvent<HTMLInputElement>): void {
    this.setState({
      value: event.currentTarget.value
    });
    
    const doSeach = debounce(500, () => (
      this.props.search(this.state.value)
    ));

    doSeach();
  }

  /**
   * Prevent form submit from refreshing page
   */
  public handleSubmit(event: React.FormEvent<HTMLButtonElement>): void {
    event.preventDefault();
  }


  /** 
   * Clear searchbar on route change
   */
  public componentWillReceiveProps(nextProps: RouteComponentProps<any>, nextState: any): void {
    const currentPath = this.props.location.pathname;
    const nextPath = nextProps.location.pathname;

    if(nextPath !== currentPath && this.props.searchterm !== '') {
      this.props.search('');
    }
  }
  
  public render(): JSX.Element {
    return (
      <form onSubmit={this.handleSubmit.bind(this)} className='searchBar'>
        <input type='text' className='searchBar__input' placeholder='Zoeken' value={this.state.value} onChange={this.handleInputChange.bind(this)} />

        <button type='submit' className='searchBar__submit'>
          <SvgIcon className='searchBar__submit-icon' icon='search' />
        </button>
      </form>
    );
  }
}

const mapStateToProps = (state: IRootState): any => ({
  searchterm: state.searchterm
});

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => ({ 
  search: (value) => dispatch(setSearchValue(
    { value } 
  ))
});

/**
 * as any workaround to fix export https://github.com/DefinitelyTyped/DefinitelyTyped/issues/18999
 */
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchBar) as any);